<template>
  <div id="app">
    <Rechner />
  </div>
</template>

<script>
import Rechner from './components/Rechner.vue'

export default {
  name: 'App',
  components: {
 Rechner
  }
}
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');
#app{
  font-family: Poppins;
}
</style>

