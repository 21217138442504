<template>
  <div>
    <div class="main show-on-desktop">
      <div>
        <p class="rechner_title">Überzeugen Sie sich selbst</p>
        <br />
        <span> Sehen Sie Selbst, wie viel Geld und Bürofläche </span>
        <p>Sie durch Dein Raum einsparen können</p>
        <div>
          <p class="warningMsg" v-if="showMessage">
            ** Bitte füllen Sie alle Felder aus!
          </p>
        </div>
      </div>
      <div>
        <div class="justify-content-center">
          <div class="inputItem px-0 my-4">
            <label for="numemployee" class="lbl clear"
              >Anzahl der Mitarbeiter :</label
            >
            <input id="numemployee" type="number" v-model="mitarbeiter" />
          </div>
          <div class="inputItem px-0 my-4">
            <label for="fleache" class="lbl clear"
              >Bürofläche in <b> m²</b> :</label
            >
            <input id="fleache" type="number"
              v-model="beurofleache" />
          </div>

          <div class="inputItem px-0 my-4">
            <label for="kosten" class="lbl clear"
              >Kosten pro <b> m² </b>in <b>€</b> :</label
            >
            <input
              id="kosten"
              type="number"
              v-model="kosten"
              @keyup.enter="rechnen"
            />
          </div>

          <div class="inputItem px-0 mt-5">
            <button class="clear mb-5" @click="rechnen">
              Berechnen
            </button>
          </div>
        </div>
      </div>

      <div v-if="showResult">
        <div>
          <table class="tbl" m-0 p-0>
            <tr class="border-bottom mb-2">
              <td class=""></td>
              <td class="text-left font-weight-bold mt-3 px-0">
                <b class="">Keine Optimierung </b>
              </td>
              <td class="text-left font-weight-bold mt-3"><b>DEIN RAUM</b></td>
            </tr>
            <tr class="border-bottom">
              <td class="head pl-5 px-0">Mitarbeiter pro Arbeitsplatz</td>
              <td class="mx-4 pl-3">{{ proArbeitplatz }}</td>
              <td class="pl-3">{{ proArbeitplatzDeinRaum }}</td>
            </tr>
            <tr class="border-bottom">
              <td class="head px-0">Benötigte Bürofläche</td>
              <td>{{ benoetigteFleache }}</td>
              <td>{{ beneotigteFleacheDeinRaum }}</td>
            </tr>
            <tr class="border-bottom my-2">
              <!--201A15 io30 - und Meetingsraumsuche-->
              <td class="head">
                Kosten zur Arbeitsplatz- und Meetingsraumsuche (pro Monat)
              </td>
              <td>{{ kostenProPlatz }}</td>
              <td>{{ kostenDeinRaum }}</td>
            </tr>
            <tr class="border-bottom">
              <td class="head">Kosten pro Jahr</td>
              <td>{{ kostenProJahr }}</td>
              <td>{{ kostenProJahrDeinRaum }}</td>
            </tr>
            <tr class="border-bottom">
              <td class="head px-0"><b>Einsparung pro Jahr</b></td>
              <td>
                <b class="pl-3">{{ einsparung }}</b>
              </td>
              <td>
                <b class="pl-5"
                  >{{ einsparungDeinRaum }} ({{ procentDeinRaum }})</b
                >
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="line clear">
        <!--     <hr style="color:green" > -->
      </div>
      <p class="note clear">
        *19,2 € ist der Durchschnitt für die deutsche Städte
      </p>
    </div>
    <div class="main-mobile show-on-mobile">
      <div>
        <p class="rechner_title">Überzeugen<br />Sie sich selbst</p>
        <br />
        <p class="text-mobile">
          Sehen Sie Selbst, wie viel Geld und Bürofläche Sie durch Dein Raum
          einsparen können
        </p>
        <div>
          <p class="warningMsg" v-if="showMessage">
            ** Bitte füllen Sie alle Felder aus!
          </p>
        </div>
      </div>
      <div>
        <div class=" my-3">
          <label for="numemployee" class=" lbl-mobile "
            >Anzahl der Mitarbeiter :</label
          >
          <input
            style="width: 96%"
            id="numemployee"
            type="number"
            v-model="mitarbeiter"
            autofocus
          />
        </div>
        <div class=" my-3">
          <label for="fleache" class="lbl-mobile "
            >Bürofläche in <b> m²</b> :</label
          >
          <input
            style="width: 96%"
            id="fleache"
            type="number"
            v-model="beurofleache"
          />
        </div>

        <div class=" my-3">
          <label for="kosten" class="lbl-mobile "
            >Kosten pro <b> m² </b>in <b>€</b> :</label
          >
          <input
            style="width: 96%"
            id="kosten"
            type="number"
            v-model="kosten"
          />
        </div>

        <div class="btn-mobile  mt-5">
          <button class="mb-5" @click="rechnen">
            Berechnen
          </button>
        </div>
      </div>
      <div v-if="showResult">
        <div class="m-0 p-0 lo clear">
          <table class="tbl" m-0 p-0>
            <tr class="border-bottom mb-2">
              <td class="text-left font-weight-bold mt-3 px-0">
                <b class="">Keine Optimierung </b>
              </td>
              <td class="text-left font-weight-bold mt-3"><b>DEIN RAUM</b></td>
            </tr>
            <tr>
              <td class="head-table-mobile">Mitarbeiter pro Arbeitsplatz</td>
            </tr>
            <tr class="border-bottom">
              <td class="mx-4 pl-3">{{ proArbeitplatz }}</td>
              <td class="pl-3">{{ proArbeitplatzDeinRaum }}</td>
            </tr>
            <tr>
              <td class="head-table-mobile ">Benötigte Bürofläche</td>
            </tr>
            <tr class="border-bottom">
              <td>{{ benoetigteFleache }}</td>
              <td>{{ beneotigteFleacheDeinRaum }}</td>
            </tr>
            <tr>
              <td class="head-table-mobile">
                Kosten zur Arbeitsplatz- und Meetingsraumsuche (pro Monat)
              </td>
            </tr>
            <tr class="border-bottom my-2">
              <td>{{ kostenProPlatz }}</td>
              <td>{{ kostenDeinRaum }}</td>
            </tr>
            <tr>
              <td class="head-table-mobile">Kosten pro Jahr</td>
            </tr>
            <tr class="border-bottom">
              <td>{{ kostenProJahr }}</td>
              <td>{{ kostenProJahrDeinRaum }}</td>
            </tr>
            <tr>
              <td class="head-table-mobile"><b>Einsparung pro Jahr</b></td>
            </tr>
            <tr class="border-bottom">
              <td>
                <b class="pl-3">{{ einsparung }}</b>
              </td>
              <td>
                <b class="pl-5"
                  >{{ einsparungDeinRaum }} ({{ procentDeinRaum }})</b
                >
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="line "></div>
      <p class="note ">
        *19,2 € ist der Durchschnitt für die deutsche Städte
      </p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      mitarbeiter: "",
      beurofleache: "",
      kosten: "",
      showResult: false,
      showMessage: false,
      windowWidth: window.innerWidth,
    };
  },

  methods: {
    rechnen() {
      if (
        this.mitarbeiter == "" ||
        this.beurofleache == "" ||
        this.kosten == ""
      ) {
        this.showMessage = true;
        this.showResult = false;
      } else {
        this.showResult = true;
        this.showMessage = false;
      }
    },
    preformatFloat: function(float){
      if(!float){
          return '';
      }
      //Index of first comma
      const posC = float.indexOf(',');

      if(posC === -1){
          //No commas found, treat as float
          return float;
      }

      //Index of first full stop
      const posFS = float.indexOf('.');

      if(posFS === -1){
          //Uses commas and not full stops - swap them (e.g. 1,23 --> 1.23)
          return float.replace(/,/g, '');
      }

      //Uses both commas and full stops - ensure correct order and remove 1000s separators
      return ((posC < posFS) ? (float.replace(/,/g,'')) : (float.replace(/\./g,'').replace(',', '')));
    },
  },
  computed: {
   
    proArbeitplatz: function() {
      if (this.mitarbeiter == "" || this.beurofleache == "") return " ";
      return 1;
    },
    proArbeitplatzDeinRaum: function() {
      if (this.mitarbeiter == "") return " ";
      let nF = Intl.NumberFormat();
      return nF.format(1.4);
    },
    benoetigteFleache: function() {
      if (this.beurofleache == "") return " ";
      let nF = Intl.NumberFormat();
      return nF.format(Math.round(parseFloat(this.beurofleache))) + " m²";
    },
    beneotigteFleacheDeinRaum: function() {
      if (this.mitarbeiter == "") return " ";
      let nF = Intl.NumberFormat();
      return nF.format(Math.round(this.beurofleache / 1.4)) + " m²";
    },
    beneotigteFleacheDeinRaumInt: function() {
      if (this.mitarbeiter == "") return " ";
      let nF = Intl.NumberFormat();
      return nF.format(Math.round(this.beurofleache / 1.4));
    },
    kostenProPlatz: function() {
      if (
        this.mitarbeiter == "" ||
        this.beurofleache == "" ||
        this.kosten == ""
      )
        return " ";
      return "/";
    },
    kostenDeinRaum: function() {
      if (
        this.mitarbeiter == "" ||
        this.beurofleache == "" ||
        this.kosten == ""
      )
        return " ";
      return parseFloat(this.mitarbeiter) * 1 + " €";
    },
    kostenProJahr: function() {
      if (
        this.mitarbeiter == "" ||
        this.beurofleache == "" ||
        this.kosten == ""
      )
        return " ";
      let nF = Intl.NumberFormat();

      return (
        nF.format(
          parseFloat(this.beurofleache) * parseFloat(this.kosten) * 12
        ) + " €"
      );
    },
    kostenProJahrDeinRaum: function() {
      if (
        this.mitarbeiter == "" ||
        this.beurofleache == "" ||
        this.kosten == ""
      )
        return " ";
      /*   let nF = Intl.NumberFormat();*/
      //alert(this.beneotigteFleacheDeinRaum);
      
      return (
        new Intl.NumberFormat("de-DE", { currency: "EUR" }).format(
          Math.round(
            (parseFloat(this.preformatFloat(this.beneotigteFleacheDeinRaumInt)) *
              parseFloat(this.kosten) +
              1 * this.mitarbeiter) *
              12
          )
        ) + " €"
      );
    },
    einsparung: function() {
      if (
        this.mitarbeiter == "" ||
        this.beurofleache == "" ||
        this.kosten == ""
      )
        return "0 €";
      return "0 €";
    },
    einsparungDeinRaum: function() {
      if (
        this.mitarbeiter == "" ||
        this.beurofleache == "" ||
        this.kosten == ""
      )
        return "0 €";

      /*      return Math.round(parseFloat(this.kostenProJahr) -  parseFloat(this.kostenProJahrDeinRaum))+ ' €'*/
      return (
        new Intl.NumberFormat("de-DE", { currency: "EUR" }).format(
          parseFloat(this.beurofleache) * parseFloat(this.kosten) * 12 -
            (
              Math.round(
                parseFloat(this.preformatFloat(this.beneotigteFleacheDeinRaum)) *
                  parseFloat(this.kosten) +
                  1 * this.mitarbeiter
              ) * 12
            ).toFixed(2)
        ) + " €"
      );
    },
    procentDeinRaum: function() {
      if (
        this.mitarbeiter == "" ||
        this.beurofleache == "" ||
        this.kosten == ""
      )
        return "0 %";
      let nF = Intl.NumberFormat();
      return (
        nF.format(
          (
            ((parseFloat(this.kostenProJahr) -
              parseFloat(this.kostenProJahrDeinRaum)) /
              parseFloat(this.kostenProJahr)) *
            100
          ).toFixed(2)
        ) + " %"
      );
    },
  },
};
</script>
<style scoped>
.main-mobile {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  padding: 20px;
  margin: 8px;
}
.input-mobile {
  width: 100%;
  text-align: center;
}
.lbl-mobile {
  width: 100%;
}
.btn-mobile {
  display: flex;
  justify-content: center;
}
.head-table-mobile {
  text-align: left;
  width: 700px !important;
  margin: 0px 2px;
}
.lblMobile {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
  display: block;
  margin-top: 20px;
  width: 80%;
}
td {
  width: 250px;
  height: 40px;
}
.head {
  text-align: left;
  width: 750px;
}
.inputItem {
  overflow: hidden;
  white-space: nowrap;
  float: left;
  width: 240px;
  margin-left: 1px;
}
.clear {
  clear: both;
}
.main {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  padding: 30px;
  width: 1024px;
  margin: 90px 130px;
}

span {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
}
.lbl {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  display: block;
}
button {
  height: 51px;
  width: 165px;
  left: 910px;
  top: 2689px;
  border-radius: 0px;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: 0px;
  text-align: center;
  left: 0%;
  right: -3.03%;
  top: 0%;
  bottom: 5.88%;
  background: #ffffff;
  border: 2px solid #212121;
  box-sizing: border-box;
  border-radius: 80px;
}
.warningMsg {
  color: red;
  text-align: left;
  font-size: small;
}

.note {
  text-align: left;
  font-size: 16px;
  color: black;
}
.rechner_title {
  font-family: Poppins;
  font-size: 36px;
  font-style: normal;
  font-weight: bold;
  line-height: 48px;
  letter-spacing: 0px;
  text-align: left;
}
span {
  font-size: 16px;
}
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
  margin: 0;
  width: 170px;
  height: 68px;
  border: none;
  border-bottom: 2px solid black;
  font-family: Poppins;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: 0px;
  text-align: left;
  outline: none;
}

.inputMobile {
  -moz-appearance: textfield;
  appearance: textfield;
  margin: 0;
  width: 100%;
  height: 68px;
  border: none;
  border-bottom: 2px solid black;
  font-family: Poppins;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: 0px;
  text-align: left;
}
.line {
  width: 98%;
  height: 47px;
  margin: 1px;
  border-bottom: 3px solid black;
  margin-bottom: 20px;
}
.show-on-mobile {
  text-align: left;
  padding-left: 10px;
  padding-top: 20px;
}
@media (min-width: 521px) {
  .show-on-mobile {
    display: none !important;
    padding: 0px 5px;
  }
}
@media (max-width: 520px) {
  .show-on-desktop {
    display: none !important;
    padding: 0px 5px;
  }
}
</style>
