import Vue from 'vue'
import VueRouter from 'vue-router'
import Rechner from '../src/components/Rechner.vue'


Vue.use(VueRouter)

const routes = [
{
path: '/',
name: 'Rechner',
component: Rechner
}
]

const router = new VueRouter({
mode: 'history',
base: process.env.BASE_URL,
routes
})

export default router


